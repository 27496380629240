import { sendApiRequest } from './api';
import { processAxiosError } from 'src/utils';

export class ProfileService {
  updatePassword = ({ oldPassword, password }) => {
    return sendApiRequest({
      method: 'post',
      url: '/admin/me/update-password',
      data: { password, oldPassword },
    })
      .then((res) => res.data)
      .catch(processAxiosError);
  };

  get = () => {
    return sendApiRequest({ method: 'get', url: '/admin/me' }).then(
      (res) => res.data
    );
  };
}

export const profileService = new ProfileService();
