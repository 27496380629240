import Axios from 'axios';
import tokenService from './token.service';

export const sendApiRequest = (config) => {
  const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      Authorization: {
        toString() {
          return `Bearer ${tokenService.getLocalAccessToken()}`;
        },
      },
      'Content-Type': 'application/json',
    },
  });
  const axios2 = Axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT2,
    headers: {
      Authorization: {
        toString() {
          return `Bearer ${tokenService.getLocalAccessToken()}`;
        },
      },
      'Content-Type': 'application/json',
    },
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.message.toLowerCase().includes('network')) {
        return axios2(config);
      }
      return Promise.reject(error);
    }
  );

  return axios(config);
};

export const cdnInstance = Axios.create({
  baseURL: process.env.REACT_APP_CDN_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

cdnInstance.interceptors.request.use(
  (config) => {
    const token = tokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token; // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const originalConfig = err.config;
//     console.log('origin.config.url', originalConfig.url);
//     if (originalConfig.url !== '/admin/login' && err.response) {
//       // Access Token was expired
//       if (err.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;

//         try {
//           const refreshToken = tokenService.getLocalRefreshToken();
//           if (!refreshToken) throw new Error('Please login again!');
//           const rs = await instance.post('/auth/refreshtoken', {
//             refreshToken: tokenService.getLocalRefreshToken(),
//           });

//           const { accessToken } = rs.data;
//           tokenService.setLocalAccessToken(accessToken);

//           return instance(originalConfig);
//         } catch (_error) {
//           return Promise.reject(_error);
//         }
//       }
//     }

//     return Promise.reject(err);
//   }
// );
